import CustomImage from 'src/legacy/components/common/custom-image';
import BodyText from 'src/legacy/components/common/text/body-text';
import ProductBadges from 'src/legacy/components/product/product-badges';
import ArrowButton from '@legacy/designs/arrow-button';
import CountdownText from '@legacy/designs/countdown-text';
import HomeComponentHeading from '@legacy/designs/home-component-heading/home-component-heading';
import NoProduct from '@legacy/designs/no-product';
import ProductCardDetails from '@legacy/designs/product-card-details';
import WishListButton from '@legacy/designs/wish-list-button';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BlockHStack } from 'src/legacy/components/blockui';
import { useCountdown } from '@hooks/use-countdown';
import { useHarmony } from '@hooks/use-harmony';
import ComponentProduct from '@models/product/component-product';
import RoutesEnum from '@routes';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TimeSaleProps } from '..';
import { useRouter } from 'next/router';
import { useExtension } from '@hooks/use-extension';
import { useGATracking } from '@hooks/use-ga-tracking';

const TimeSaleA: React.FC<TimeSaleProps> = ({ channelComponent }) => {
    const { theme } = useHarmony();
    const router = useRouter();
    const { extensionStatus } = useExtension();
    const { logEvent } = useGATracking();
    const t = useTranslations();
    const { time, end } = useCountdown(channelComponent.endDate);

    return (
        <div
            css={css`
                width: 100%;
                padding: 16px 0 32px;
                background-color: ${theme.background.config};
            `}
        >
            <HomeComponentHeading title={channelComponent.title}>
                {channelComponent.firstItem?.pageUrl && (
                    <Link href={channelComponent.firstItem.pageUrl}>
                        <ArrowButton
                            onClick={() => {
                                logEvent('select_promotion', {
                                    promotion_name: channelComponent.title,
                                });
                            }}
                        >
                            {t('home.view')}
                        </ArrowButton>
                    </Link>
                )}
            </HomeComponentHeading>
            <div
                role="button"
                css={css`
                    margin-top: 16px;
                    position: relative;
                    cursor: pointer;
                    .swiper-slide {
                        width: calc(min(100vw, ${extensionStatus?.maxScreenWidth}px) - 70px);
                    }
                    .product {
                        position: relative;
                        width: 100%;
                        line-height: 1;
                        &.disable {
                            cursor: not-allowed;
                            .image {
                                &::after {
                                    opacity: 0.6;
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    background-color: ${theme.white};
                                }
                            }
                        }
                        .image {
                            position: relative;
                            width: 100%;
                            height: calc(min(100vw, ${extensionStatus?.maxScreenWidth}px) - 70px);
                            border: 1px solid ${theme.gray[50]};
                            margin-bottom: 20px;
                        }
                    }
                `}
            >
                <Swiper spaceBetween={15} centeredSlides={true} slidesPerView={'auto'}>
                    {channelComponent.firstItem?.mainDisplayProductList &&
                        channelComponent.firstItem.mainDisplayProductList.length > 0 &&
                        channelComponent.firstItem.mainDisplayProductList.map((product: ComponentProduct) => {
                            const isDisabled = channelComponent.isBeforeStart || end || product.isSoldOut;
                            return (
                                <SwiperSlide key={product.id}>
                                    <div
                                        className={`product ${isDisabled ? 'disable' : ''}`}
                                        onClick={
                                            isDisabled
                                                ? undefined
                                                : () => {
                                                      logEvent('select_promotion', {
                                                          promotion_name: channelComponent.title,
                                                      });
                                                      router.push({ pathname: RoutesEnum.ProductDetail, query: { productId: product.id } });
                                                  }
                                        }
                                    >
                                        <div className="image">
                                            {product.image && <CustomImage src={product.image} alt={product.title} layout="fill" objectFit="cover" />}
                                            <AbsoluteContainer>
                                                <BlockHStack align="distribute">
                                                    <ProductBadges isSoldOut={product.isSoldOut} isSpecial={product.timesaleAppliedNow} />
                                                    <PaddedContainer padding="2px">
                                                        <WishListButton product={product} />
                                                    </PaddedContainer>
                                                </BlockHStack>
                                            </AbsoluteContainer>
                                        </div>
                                        <ProductCardDetails product={product} cols={1} isEllipsisBrandName={false} />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    {(channelComponent.firstItem?.mainDisplayProductList.length || 0) < 1 && (
                        <BlockHStack align="center" height="100vw">
                            <NoProduct size="300px" />
                        </BlockHStack>
                    )}
                    <TimerContainer>
                        <TimerDisplay theme={theme}>
                            {channelComponent.isBeforeStart ? (
                                <BodyText weight="bold" size="15px" color={theme.white}>
                                    {t('home.saleStarts')}
                                </BodyText>
                            ) : (
                                <>
                                    {end ? (
                                        <BodyText weight="bold" size="15px" color={theme.white}>
                                            {t('home.saleHasEnded')}
                                        </BodyText>
                                    ) : (
                                        <CountdownText timeRemaining={time} />
                                    )}
                                </>
                            )}
                        </TimerDisplay>
                    </TimerContainer>
                </Swiper>
            </div>
        </div>
    );
};

export default TimeSaleA;

const TimerContainer = styled.div`
    position: absolute;
    width: 100%;
    z-index: ${ThemeUtils.zIndex.above};
    left: 0;
    top: calc(100% - 108px);
    padding: 0 42px;
`;

const TimerDisplay = styled.div<IThemeProps>`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 44px;
        border-radius: 48px;
        background-color: ${theme.primary.default};
    `}
`;

const AbsoluteContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
`;
